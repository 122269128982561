//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import AdminViewModal from '@/modules/admins/components/admin-view-modal.vue';
import AdminFormModal from '@/modules/admins/components/admin-form-modal.vue';
const { fields } = UserModel;

export default {
  name: 'app-place-list-table',
    components: {
    [AdminViewModal.name]: AdminViewModal,
    [AdminFormModal.name]: AdminFormModal
  },
    props: {
    
  },

  data() {
    return {
      record: null,
      createAdminDialogVisible:false ,
      createAdminModalVisible: false,
      viewAdminDialogVisible:false ,
      viewAdminModalVisible: false,
      deleteAdminDialogVisible:false ,
      deleteAdminModalVisible: false,
      modalSuccessVisible: false,


      selectedRow: null,
      selectedId: null,
      dialogType: null,
      modalVisible: false,
      dialogVisible: false,
      pagination: {
        page: 1,
        rowsPerPage: 10 
      },
      page: 1,
      columns: [
        {
          name: 'placeName',
          align: 'center',
          label: i18n('tables.place.placeName'),
          field: 'placeName',
        },              
        //  {
        //   name: 'description',
        //   align: 'center',
        //   label: i18n('tables.place.description'),
        //   field: 'description',
        // },
        
        {
          name: 'city',
          align: 'center',
          label: i18n('tables.place.city'),
          field: 'city',
        }, 
        {
          name: 'placeType',
          align: 'center',
          label: i18n('tables.place.placeType'),
          field: 'placeType',
        },
         {
          name: 'status',
          align: 'center',
          label: i18n('tables.place.status'),
          field: 'status',
        },
        {
          name: 'action',
          align: 'center',
          label: i18n('tables.place.action'),
          field: 'action',
        },



      ],

      placeTab: 'activePlaces',
      status: ''
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'place/list/rows',
      count: 'place/list/count',
      disablePrevious: 'place/list/disablePrevious',
      disableNext: 'place/list/disableNext',
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'place/list/loading',
      isFirstPage: 'place/list/isFirstPage',
      isLastPage: 'place/list/isLastPage'
    }),

    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    },
    getItemsPerPage() {
          // const { screen } = this.$q;
          //   if (screen.lt.sm) {
          //       return 4;
          //   }
          //   if (screen.lt.md) {
          //       return 6;
          //   }
          //   if (screen.lt.lg) {
          //       return 9;
          //   }
          //   if (screen.lt.xl) {
          //       return 9;
          //   }
            return 10;
        },

        isRTL(){
          return i18n.locale == 'ar'
        }
  },

  watch:{
    async placeTab(newval){
      // console.log(newval);
      // debugger
      this.page = 1
      if(newval == 'activePlaces'){
        const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage
        }
        this.status = 'accepted'
        await this.doFetch({status: this.status, orderBy: 'createdAt', pagination})   
      }
      if(newval == 'suggestedPlaces'){
        const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage
        }
        this.status = 'pending'
        await this.doFetch({status: this.status, orderBy: 'createdAt', pagination})
      }
      
    }
  },
  async  mounted() {
  //  await this.doFilter();
  //  await this.doMountTable(this.$refs.table);
    const pagination = {
      sortBy: "desc",
      limit: this.getItemsPerPage
    }
    await this.doFetch({status: 'accepted', orderBy: 'createdAt', pagination})
    this.status = 'accepted'   
  },

  methods: {
    ...mapActions({
      finishLoading: 'place/list/finishLoading',
      doFetch: 'place/list/doFetch',
      doMountTable: 'place/list/doMountTable',
    }),
    goToPlace(id){
      this.$router.push({
        name: 'placeView',
        params:{
          id
        }
      })
    },

    goToAddNewPlace(){
      this.$router.push({
        name: 'placeNew'
      })
    },

    async doFilter() {
      const filter = {};
      return this.doFetch({
        filter,
      });
    },
    async goToPreviousPage(){
      console.log('go to previous page');
      console.log('prev');
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "prev",
          page: this.page
      }
      await this.doFetch({status: this.status, orderBy, pagination})
      this.page -= 1
    },
    async loadMore(){
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "next",
          page: this.page
      }
      await this.doFetch({status: this.status, orderBy, pagination})
      this.page += 1
    },
    displayName(value){
      return value ? this.isRTL ? value.name.ar : value.name.en : '___'
    },
    displayDescription(value){
      return value ? this.isRTL ? value.ar : value.en : '___'
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    // presenter function display data from model to fields 
    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      console.log(this.selectedId);
      switch (operation) {
          case 'delete':
          this.dialogType = 'delete'
          break;
          case 'edit':
          this.dialogType = 'edit'
          break;
          case 'view':
          this.dialogType = 'view'
          break;  
          default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
          this.modalVisible = false;
          this.dialogType = undefined
      }, 500);
    },

    onOpenCreateAdminModal() {
      this.createAdminModalVisible = true;
      this.createAdminDialogVisible = true;
    },
    onCreateAdminModalClose() {
      this.createAdminDialogVisible = false;
      setTimeout(() => {
        this.createAdminModalVisible = false;
      }, 200);
    },
    onOpenViewAdminModal() {
      this.viewAdminModalVisible = true;
      this.viewAdminDialogVisible = true;
    },
    onViewAdminModalClose() {
      this.viewAdminDialogVisible = false;
      setTimeout(() => {
        this.viewAdminModalVisible = false;
      }, 200);
    },
    onModalSuccessClose() {
      console.log('CLOSED');
      this.success = false;
      setTimeout(() => {
        this.modalSuccessVisible = false;
      }, 200);
    },
    viewAdmin(row) {
      this.selectedRow=row
      this.onOpenViewAdminModal()
    },
    adminSaved() {
      this.onCreateAdminModalClose();
      this.success = true;
      this.modalSuccessVisible = true;
    },
    deleteAdmin(row){
      this.selectedRow=row
      this.onOpenDeleteAdminModal()
    },
    onOpenDeleteAdminModal() {
      this.deleteAdminModalVisible = true;
      this.deleteAdminDialogVisible = true;
    },
    onDeleteAdminModalClose() {
      this.deleteAdminDialogVisible = false;
      setTimeout(() => {
        this.deleteAdminModalVisible = false;
      }, 200);
    },
    async doDestroyWithConfirm() {      
            try {
                console.log(this.selectedId);
                await this.doDisableAllSelected([this.selectedId])     
                this.onModalClose()  
                debugger
            } catch (error) {
              console.log(error);
              debugger
                // no
            }
        },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
