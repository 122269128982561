//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import PlaceListFilter from '@/modules/place/components/place-list-filter.vue';
import PlaceListTable from '@/modules/place/components/place-list-table.vue';
import { mapGetters } from 'vuex'
export default {
  name: 'app-place-list-page',

  components: {
    // [PlaceListFilter.name]: PlaceListFilter,
    [PlaceListTable.name]: PlaceListTable,
  },
  computed:{
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs'
    })
  },
  methods:{
    onOpenModal(){
      console.log('open');
    },
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    goToAddNewPlace(){
      this.$router.push({
        name: 'placeNew'
      })
    }
  }
};
